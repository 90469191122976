import { computed } from 'vue'

import { useStore } from '@web/store'
const store = useStore()

function useCatalog () {
  const items = computed(() => store.getters['catalog/items'])
  const totalCount = computed(() => store.getters['catalog/getTotalCount'])

  async function fetchData (payload?: { page?: number }) {
    await store.dispatch('catalog/fetchCachedItems', { page: payload?.page })
  }

  async function fetchDataForce () {
    await store.dispatch('catalog/fetchItems')
  }

  return {
    items,
    totalCount,
    fetchData,
    fetchDataForce
  }
}

export default useCatalog
